<template>
  <card-container backButton closeButton>
    <div class="flex flex-col px-12 pt-24 pb-6">
      <div class="text-3xl text-center">Glemt passord</div>
      <div class="mt-6">
        Fyll inn mailadressen du bruker til Telt så sender vi deg en mail med link for å sette nytt
        passord.<br /><br />
        Hvis mailen ikke dukker opp i innboksen kan du sjekke spam.
      </div>
      <form @submit.prevent="sendPasswordResetEmail" class="flex flex-col items-center mt-6">
        <input
          type="text"
          ref="emailInput"
          v-model="email"
          placeholder="E-post"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': emailInvalid }"
        />
        <input
          type="submit"
          :value="loading ? 'Sender...' : 'Send'"
          :disabled="loading"
          class="px-10 py-4 mt-8 text-lg bg-gray-200 rounded-full disabled:opacity-50"
        />
        <transition name="fade">
          <div v-show="error" class="mt-6 text-center text-red-600">{{ errorMessage }}</div>
        </transition>
      </form>
    </div>
  </card-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import CardContainer from '../components/CardContainer.vue'

export default {
  name: 'UserPasswordReset',
  components: { CardContainer },
  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = ref(false)
    const error = ref(false)
    const errorMessage = ref('')

    const email = ref('')
    const emailInput = ref()
    const emailInvalid = ref(false)

    const sendPasswordResetEmail = () => {
      loading.value = true
      error.value = false
      errorMessage.value = ''
      emailInvalid.value = false
      store
        .dispatch('sendPasswordResetEmail', email.value.trim())
        .then(() => {
          router.push({ name: 'signin', params: { transition: 'slide-forward' } })
        })
        .catch(err => {
          error.value = true
          loading.value = false
          emailInput.value.focus()
          emailInvalid.value = true
          switch (err.code) {
            case 'auth/invalid-email':
              errorMessage.value = 'Ikke en gyldig e-postadresse'
              break
            case 'auth/user-not-found':
              errorMessage.value = 'Ingen bruker registrert med denne adressen'
              break
            default:
              errorMessage.value = err.message
          }
        })
    }

    return {
      loading,
      error,
      errorMessage,
      email,
      emailInput,
      emailInvalid,
      sendPasswordResetEmail,
    }
  },
}
</script>
