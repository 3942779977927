<template>
  <div
    class="absolute flex items-center justify-center w-full h-full px-4 pt-6 pb-12 pointer-events-auto"
  >
    <div class="relative w-full h-full xs:w-96 max-h-160">
      <div class="h-full overflow-scroll bg-gray-100 rounded-3xl">
        <slot></slot>
      </div>
      <button
        v-if="backButton"
        @click="$router.back()"
        class="absolute w-10 h-10 bg-gray-200 rounded-full left-6 top-6"
      >
        <i class="fa-light fa-angle-left fa-lg"></i>
      </button>
      <button
        v-if="closeButton"
        @click="$router.push({ name: 'home', params: { transition: 'slide-bottom' } })"
        class="absolute w-10 h-10 bg-gray-200 rounded-full right-6 top-6"
      >
        <i class="fa-light fa-xmark fa-lg"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backButton: Boolean,
    closeButton: Boolean,
  },
}
</script>

<style></style>
